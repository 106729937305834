import {
  UseIntersectionObserverOptions,
  useIntersectionObserver,
} from '../../../../../../helpers/hooks/useIntersectionObserver';

export type EpisodeIoSentinelProps = {
  className?: string;
} & UseIntersectionObserverOptions;

/**
 * Sentinel component using the `useIntersectionObserver` (IO) hook to trigger
 * fetching the next page of Episode data based on the condition given to `isEnabled` prop.
 *
 * Useful to isolate rerenders caused by the IO hook state updates.
 */
export default function EpisodeIoSentinel({
  className,
  threshold = 0.1,
  isEnabled,
  onIntersect,
  root,
  rootMargin,
}: EpisodeIoSentinelProps): JSX.Element {
  const { refCallback } = useIntersectionObserver({
    isEnabled,
    onIntersect,
    threshold,
    root,
    rootMargin,
  });

  return (
    <div className={className} ref={refCallback} data-testid="IO-sentinel-id" />
  );
}
